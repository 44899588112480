import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import auth from '@/auth';
import store from '@/store';
import axios from 'axios';
import semver from 'semver';
// import ActivityBasedTimer from 'services/activityBasedTimer';

import DefaultLayout from '@/layouts/DefaultLayout'

let lastVersionCheck = Date.now();
const appTimestamp = Date.now();

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/login',
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/team',
    name: 'Team',
    component: DefaultLayout,
    redirect: '/team/dashboard',
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'dashboard',
        name: 'Team Dashboard',
        component: () => import('@/views/team/Dashboard.vue'),
        meta: { page_id: 1 }
      },
      {
        path: 'clients',
        name: 'Team Pregled accountov',
        component: () => import('@/views/team/Clients.vue'),
        meta: { page_id: 2 }
      },
      {
        path: 'client/:id',
        name: 'Team Account',
        component: () => import('@/views/team/Client.vue'),
        meta: { page_id: 2 }
      },
      {
        path: 'brainstormings',
        name: 'Team Brainstormingi',
        component: () => import('@/views/team/Brainstormings.vue'),
        meta: { page_id: 3 }
      },
      {
        path: 'brainstorming/:id',
        name: 'Team Brainstorming',
        component: () => import('@/views/team/Brainstorming.vue'),
        meta: { page_id: 3 }
      },
      {
        path: 'scenes',
        name: 'Team Scenariji',
        component: () => import('@/views/team/Scenes.vue'),
        meta: { page_id: 4 }
      },
      {
        path: 'posts',
        name: 'Team Plani objav',
        component: () => import('@/views/team/Posts.vue'),
        meta: { page_id: 5 }
      },
      {
        path: 'productions',
        name: 'Team Produkcije',
        component: () => import('@/views/team/Productions.vue'),
        meta: { page_id: 17 }
      },
      {
        path: 'production/:id',
        name: 'Team Produkcija',
        component: () => import('@/views/team/Production.vue'),
        meta: { page_id: 17 }
      },
      {
        path: 'reports',
        name: 'Team Reports',
        component: () => import('@/views/team/Reports.vue'),
        meta: { page_id: 19 }
      },
      {
        path: 'report/:id',
        name: 'Team Report',
        component: () => import('@/views/team/Report.vue'),
        meta: { page_id: 19 }
      },
      {
        path: 'analytics/:id',
        name: 'Team Analytics',
        component: () => import('@/views/shared/Analytics.vue'),
        meta: { page_id: 18 }
      },
      {
        path: 'client-analytics',
        name: 'Team Client Analytics',
        component: () => import('@/views/team/ClientAnalytics.vue'),
        meta: { page_id: 36 }
      },
      {
        path: 'client-posts',
        name: 'Team Client Posts',
        component: () => import('@/views/team/ClientPosts.vue'),
        meta: { page_id: 37 }
      },
      {
        path: 'brand-analysis',
        name: 'Team Brand Analysis',
        component: () => import('@/views/team/BrandAnalysis.vue'),
        meta: { page_id: 38 }
      },
      {
        path: 'influencers',
        name: 'Team Vplivneži',
        component: () => import('@/views/shared/Influencers.vue'),
        meta: { page_id: 6 }
      },
      {
        path: 'brands',
        name: 'Team Znamke',
        component: () => import('@/views/shared/Brands.vue'),
        meta: { page_id: 7 }
      },
      {
        path: 'articles',
        name: 'Team Članki',
        component: () => import('@/views/team/Articles.vue'),
        meta: { page_id: 7 }
      },
      {
        path: 'team',
        name: 'Team Ekipa',
        component: () => import('@/views/team/Users.vue'),
        meta: { page_id: 8 }
      },
      {
        path: 'accounting',
        name: 'Team Accounting',
        component: () => import('@/views/team/Accounting.vue'),
        meta: { page_id: 35 }
      },
      {
        path: 'invoice-overview',
        name: 'Team Invoice Overview',
        component: () => import('@/views/team/InvoiceOverview.vue'),
        meta: { page_id: 42 }
      },
      {
        path: 'client-overview',
        name: 'Team Client Overview',
        component: () => import('@/views/team/ClientOverview.vue'),
        meta: { page_id: 39 }
      },
      {
        path: 'team-accounts',
        name: 'Team Team Accounts',
        component: () => import('@/views/team/TeamAccounts.vue'),
        meta: { page_id: 34 }
      },
      {
        path: 'pnl',
        name: 'Team PnL',
        component: () => import('@/views/team/PnL.vue'),
        meta: { page_id: 41 }
      },
      {
        path: 'invoices',
        name: 'Team Invoices',
        component: () => import('@/views/team/Invoices.vue'),
        meta: { page_id: 40 }
      },
      {
        path: 'worktime',
        name: 'Team Worktime',
        component: () => import('@/views/team/Worktime.vue'),
        meta: { page_id: 29 }
      },
      {
        path: 'personal-finances',
        name: 'Team Personal Finances',
        component: () => import('@/views/team/PersonalFinances.vue'),
        meta: { page_id: 33 }
      },
      {
        path: 'verify/productions',
        name: 'Verify Productions',
        component: () => import('@/views/team/verify/VerifyProductions.vue'),
        meta: { page_id: 23 }
      },
      {
        path: 'verify/production/:id',
        name: 'Verify Production',
        component: () => import('@/views/team/Production.vue'),
        meta: { page_id: 23 }
      },
      {
        path: 'verify/reports',
        name: 'Verify Reports',
        component: () => import('@/views/team/Reports.vue'),
        meta: { page_id: 24 }
      },
      {
        path: 'verify/report/:id',
        name: 'Verify Report',
        component: () => import('@/views/team/Report.vue'),
        meta: { page_id: 24 }
      },
      {
        path: 'verify/scenes',
        name: 'Verify Scenes',
        component: () => import('@/views/team/Scenes.vue'),
        meta: { page_id: 25 }
      },
      {
        path: 'verify/posts',
        name: 'Verify Posts',
        component: () => import('@/views/team/Posts.vue'),
        meta: { page_id: 28 }
      }
    ],
  },
  {
    path: '/clients',
    name: 'Clients',
    component: DefaultLayout,
    redirect: '/clients/dashboard',
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/clients/dashboard',
        name: 'Client Dashboard',
        component: () => import('@/views/clients/Dashboard.vue'),
        meta: { page_id: 14 }
      },
      {
        path: 'scenes',
        name: 'Client Scenes',
        component: () => import('@/views/clients/Scenes.vue'),
        meta: { page_id: 15 }
      },
      {
        path: 'posts',
        name: 'Client Posts',
        component: () => import('@/views/clients/Posts.vue'),
        meta: { page_id: 16 }
      },
      {
        path: 'results',
        name: 'Client Results',
        component: () => import('@/views/shared/Analytics.vue'),
        meta: { page_id: 22 }
      },
      {
        path: 'influencers',
        name: 'Client Influencers',
        component: () => import('@/views/shared/Influencers.vue'),
        meta: { page_id: 31 }
      },
      {
        path: 'brands',
        name: 'Client Brands',
        component: () => import('@/views/shared/Brands.vue'),
        meta: { page_id: 30 }
      }
    ]
  },
  {
    path: '/',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    meta: { allUsers: true },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
        meta: { guestOnly: true }
      },
      {
        path: 'register/:token',
        name: 'Register',
        component: () => import('@/views/pages/Register'),
        meta: { guestOnly: true }
      },
    ],
  },
  {
    path: '/reports',
    name: 'Reports',
    meta: { allUsers: true },
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '/reports/accounts/:type/:limit',
        name: 'AccountsReport',
        component: () => import('@/reports/Accounts.vue'),
      },
      {
        path: '/reports/account/:id',
        name: 'AccountReport',
        component: () => import('@/reports/Account.vue'),
      },
      {
        path: '/reports/production/:id',
        name: 'Production Report',
        component: () => import('@/reports/Production.vue'),
        meta: { page_id: 17 }
      },
      {
        path: '/reports/yearly/:year',
        name: 'Yearly Report',
        component: () => import('@/reports/YearlyOverview.vue'),
      }
    ]
  },
  {
    path: "/:catchAll(.*)",
    name: "Page not found",
    redirect: '/404'
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach(async (to,from,next) => {
  if (Date.now() - appTimestamp > 1000 * 60 * 60 * 24) {
    // do at least one refresh per day
    window.location.href = to.href;
    window.location.reload(true);
    return;
  } else if (Date.now() - lastVersionCheck > 1000 * 60 * 10) {
    // check for new version at a frequency at least 10 minutes
    lastVersionCheck = Date.now();
    await axios.get(process.env.VUE_APP_API_URL + 'version').then((response) => {
      if (semver.lt(store.state.packageVersion, response.data.version)) {
        window.location.href = to.href;
        window.location.reload(true);
        return;
      }
    })
  }

  auth.userPromise.then(() => {
    let currentUser = store.state.user;
    let requireAuth = to.matched.some(record => record.meta.requiresAuth);
    let guestOnly = to.matched.some(record => record.meta.guestOnly);

    if (requireAuth && !currentUser) {
      next('/login');
    } else if (guestOnly && currentUser) {
      // chech what the user is
      if (currentUser.team) {
        next('/team');
      } else {
        next('/clients/dashboard');
      }
    } else {
      if (currentUser) {
        if (to.matched.some(record => currentUser.privileges.includes(record.meta.page_id) || record.meta.allUsers)) next();
        else next('/404');
      } else {
        next()
      }
    }
  })
})

export default router
