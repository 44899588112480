import { createStore } from 'vuex'
import { useCookies } from "vue3-cookies";
import auth from '@/auth';
import axios from 'axios';
import moment from 'moment';

const copyKeys = ["user_id","team","client","clients","agency_id","agile_group","full_name","nickname","navs","agency_name","agency_logo","is_influencer","account_id","is_operations"];
const cookies = useCookies().cookies;

let browserLanguage = navigator.language || navigator.userLanguage;
if (browserLanguage && browserLanguage.length > 2) browserLanguage = browserLanguage.substr(0,2);
else if (!browserLanguage) browserLanguage = 'en';
// check if lang is supported
const supportedLanguages = ['en','sl','de','es','hr','it','mk','sq','sr'];
if (!supportedLanguages.includes(browserLanguage)) browserLanguage = 'en';

if (!cookies.isKey('theme')) cookies.set('theme','dark',60*60*24*365); // 1 year
if (!cookies.isKey('currentClient')) cookies.set('currentClient',0,60*60*24*14); // 14 days
if (!cookies.isKey('currentGroup')) cookies.set('currentGroup',0,60*60*24*14); // 14 days
if (!cookies.isKey('usersFilter')) cookies.set('usersFilter',"[]",60*60*24*14); // 14 days
if (!cookies.isKey('language')) cookies.set('language',browserLanguage,60*60*24*7); // 7 days, to change if new language is added
if (!cookies.isKey('analyticsDays')) cookies.set('analyticsDays',90,60*60*24*365); // 1 year
if (!cookies.isKey('analyticsPosts')) cookies.set('analyticsPosts',30,60*60*24*365); // 1 year

export default createStore({
  state: {
    packageVersion: process.env.PACKAGE_VERSION || 'N/A',
    asideVisible: false,
    sidebarVisible: '',
    sidebarUnfoldable: false,
    theme: cookies.get('theme'),
    currentClient: cookies.get('currentClient'),
    currentGroup: cookies.get('currentGroup'),
    usersFilter: JSON.parse(cookies.get('usersFilter')),
    user: null,
    language: cookies.get('language'),
    analyticsDays: cookies.get('analyticsDays'),
    analyticsPosts: cookies.get('analyticsPosts'),
    overviewStart: moment().subtract(1, 'months').startOf('month').set({hour:12}).toISOString(),
    overviewEnd: moment().subtract(1, 'months').endOf('month').set({hour:12}).toISOString(),
    overviewInvoicing: false,
    overviewAdvanced: false,
    showCategories: false,
    reportsClient: null,
    pnlYear: moment().year(),
    accRefCode: '',
  },
  mutations: {
    toggleAside(state) {
      state.asideVisible = !state.asideVisible
    },
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleTheme(state, payload) {
      state.theme = payload.value
      cookies.set('theme',payload.value,60*60*24*365)
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
    updateCurrentClient(state,clientId) {
      state.currentClient = clientId;
      cookies.set('currentClient',clientId,60*60*24*14)
    },
    updateCurrentGroup(state,groupId) {
      if (groupId !== null && groupId < 1) return;
      state.currentGroup = groupId;
      cookies.set('currentGroup',groupId,60*60*24*14)
    },
    updateReportsClient(state,clientId) {
      state.reportsClient = clientId;
    },
    updateUsersFilter(state,filterUsers) {
      state.usersFilter = filterUsers;
      cookies.set('usersFilter',JSON.stringify(filterUsers),60*60*24*14)
    },
    updateUser(state, user) {
      state.user = user;
      if (!user.team && user.clients.length > 0 && state.currentClient == 0) {
        state.currentClient = user.clients[0];
        cookies.set('currentClient',user.clients[0].client_id,60*60*24*14)
      }
    },
    updateLanguage(state, language) {
      state.language = language;
      cookies.set('language',language,60*60*24*365)
    },
    updateAnalyticsDays(state, days) {
      state.analyticsDays = days;
      cookies.set('analyticsDays',days,60*60*24*365)
    },
    updateAnalyticsPosts(state, posts) {
      state.analyticsPosts = posts;
      cookies.set('analyticsPosts',posts,60*60*24*365)
    },
    logoutUser(state) {
      state.user = null;
    },
    updateOverviewStart(state, start) {
      state.overviewStart = start;
    },
    updateOverviewEnd(state, end) {
      state.overviewEnd = end;
    },
    toggleOverviewInvoicing(state) {
      state.overviewInvoicing = !state.overviewInvoicing;
    },
    toggleOverviewAdvanced(state) {
      state.overviewAdvanced = !state.overviewAdvanced;
    },
  },
  getters: {
    user: state => state.user,
    isLogged: state => (state.user !== null),
    appVersion: state => state.packageVersion,
    userId: state => (state.user ? state.user.user_id : 0),
    agencyId: state => (state.user ? state.user.agency_id : 0),
    agileGroup: state => (state.user ? state.user.agile_group : 0),
    userClients: state => (state.user ? state.user.clients : []),
    client: state => (state.user ? state.currentClient : null),
    isHub: state => (state.user ? state.user.agency_id == 1 : false),
    isInfl: state => (state.user ? state.user.is_influencer == 1 : false),
    isAdmin: state => (state.user ? state.user.is_operations == 1 : false),
    inflAccId: state => (state.user ? state.user.account_id : 0),
    analyticsDays: state => state.analyticsDays,
    analyticsPosts: state => state.analyticsPosts,
    currentGroup: state => !!state.currentGroup && state.currentGroup > 0 ? state.currentGroup : null,
  },
  actions: {
    setCurrentUser: ({ commit }) => {
      return new Promise((resolve, reject) => {
        let currentUser = auth.user();
        axios.post(process.env.VUE_APP_API_URL + 'auth/user',currentUser)
          .then((response) => {
            let dbUser = response.data;
            for (let i = 0; i < copyKeys.length; i++) {
              const key = copyKeys[i];
              currentUser[key] = dbUser[key];
            }
            currentUser.privileges = JSON.parse("[" + dbUser.privileges + "]");
            console.log(currentUser);
            commit('updateUser',currentUser);
            resolve(dbUser.team ? 'team' : 'client');
          })
          .catch((error) => {
            auth.logout().then(() => {
              commit('logoutUser');
            })
            reject(error.response.data.message);
            // return error.response.data.message;
          })
      });
    }
  },
  modules: {},
})
